import Cookies from "js-cookie";
import { Loader2 } from "lucide-react";
import { FC, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { login } from "../../api/auth.api";
import Logo from "../../assets/media/svg/Wassapy-Logo.svg";
import Input from "../../shared/Form/Input";
import {
  setIsAuth,
  setIsLoginModalOpened,
  setIsPopupOpened,
  setIsVerifyAccountModalOpened,
  setUser,
} from "../../store/actions";

export interface ILoginProps {
  onSignUp: () => void;
  onForgetPassword: () => void;
}

const Login: FC<ILoginProps> = ({ onSignUp, onForgetPassword }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    rememberMe: false,
  });

  const handleLogIn = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    const { email, password, rememberMe } = formData;
    const { success, errors, data, token } = await login({
      email,
      password,
    });

    setLoading(false);

    if (!success) return errors.forEach((error: string) => toast.error(error));

    // Set the token in the cookies
    Cookies.set(process.env.REACT_APP_AUTH_COOKIE_NAME || "token", token, {
      ...(rememberMe && { expires: 30 }),
      secure: true,
    });

    // Set the user data in the store
    dispatch(setUser(data));
    dispatch(setIsAuth(true));

    // Open the verify account modal if the user is not verified
    if (!data.isVerified) return dispatch(setIsVerifyAccountModalOpened(true));

    // Redirect the user to the pricing page if he is not subscribed
    if (!data.isActive || !data.isSubscribed) navigate("/prices");

    // Close the modal
    dispatch(setIsLoginModalOpened(false));
    dispatch(setIsPopupOpened(false));
  };

  return (
    <div className="max-w-2xl bg-white my-[88px] mx-auto lg:px-24 px-12 py-14 rounded-lg overflow-auto">
      <img src={Logo} alt="Wassapy" className="h-10" />
      <div className="mt-8">
        <div className="text-black lg:text-4xl text-2xl font-black">
          Welcome,
        </div>
        <div className="text-black lg:text-xl text-base font-normal mt-4">
          Welcome! Please enter your details.
        </div>
      </div>

      <form className="mt-6" onSubmit={handleLogIn}>
        <Input
          value={formData.email}
          className="mt-4"
          onTextChange={(value) =>
            setFormData({
              ...formData,
              email: value,
            })
          }
          type="email"
          placeholder="Email"
          disabled={loading}
        />
        <Input
          value={formData.password}
          className="mt-4"
          onTextChange={(value) =>
            setFormData({
              ...formData,
              password: value,
            })
          }
          type="password"
          placeholder="Password"
          disabled={loading}
        />

        <div className="flex items-center justify-between mt-6">
          <div>
            <label className="inline-flex items-center mt-4">
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 text-primary"
                checked={formData.rememberMe}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    rememberMe: e.target.checked,
                  })
                }
              />
              <span className="ml-2 text-base text-black font-normal">
                Remember Me
              </span>
            </label>
          </div>

          <div className="text-black text-base font-normal mt-4 text-right">
            <span
              className="text-primary cursor-pointer underline"
              onClick={() => {
                onForgetPassword();
              }}
            >
              Forget Password
            </span>
          </div>
        </div>

        <button
          type="submit"
          className="mt-10 mx-auto inline-flex items-center text-white px-8 py-[14.50px] text-lg font-bold w-full bg-primary active:bg-primary-dimmed hover:bg-primary-dimmed rounded-[50px] justify-center gap-2.5"
        >
          {loading ? <Loader2 className="animate-spin" /> : "Log In"}
        </button>

        <div>
          <div className="text-black text-base font-normal mt-7 text-center">
            Don’t have an account? &nbsp;
            <span
              className="text-primary cursor-pointer underline"
              onClick={() => {
                onSignUp();
              }}
            >
              Sign Up Now
            </span>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Login;
