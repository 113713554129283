import OTPInput, { ResendOTP } from "otp-input-react";
import { FC, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

import { resendOtp, verifyUser } from "../../api/auth.api";
import Logo from "../../assets/media/svg/Wassapy-Logo.svg";
import useSession from "../../hooks/useSession";
import { IState } from "../../models/store";
import { setIsResetPasswordModalOpened } from "../../store/actions";

export interface IVerifyAccountProps {
  onDone: () => void;
  type: "verify-account" | "forget";
}

const VerifyAccount: FC<IVerifyAccountProps> = ({ onDone, type }) => {
  const { fetchUser } = useSession();
  const dispatch = useDispatch();
  const [OTP, setOTP] = useState("");
  const [loading, setLoading] = useState(false);
  const [isTimerComplete, setIsTimerComplete] = useState(false);
  const { user } = useSelector((state: IState) => state.appReducer);

  const handleVerifyAccount = async () => {
    setLoading(true);

    const { success, errors, message } = await verifyUser(
      parseInt(OTP),
      user?.id as number
    );

    setLoading(false);

    if (!success) return errors.forEach((error: string) => toast.error(error));

    toast.success(message);

    await fetchUser();

    {
      type === "forget"
        ? dispatch(setIsResetPasswordModalOpened(true))
        : onDone();
    }
  };

  useEffect(() => {
    if (OTP.length === 4) {
      handleVerifyAccount();
    }
  }, [OTP]);

  const getOTP = async () => {
    if (!user) return;

    const { success, errors, message } = await resendOtp(user.email);
    if (!success) return errors.forEach((error: string) => toast.error(error));

    toast.success(message);
  };

  useEffect(() => {
    getOTP();
  }, []);

  return (
    <div className="max-w-2xl bg-white my-[88px] mx-auto lg:px-24 px-12 py-14 rounded-lg overflow-auto">
      <img src={Logo} alt="Wassapy" className="h-10" />
      <div className="mt-8">
        <div className="text-black lg:text-4xl text-2xl font-black">
          Verify Your Email
        </div>
        <div className="text-black lg:text-xl text-base font-normal mt-4">
          Enter the 4-digit code sent via Email to <br />{" "}
          <div className="text-gray-400 italic">{user?.email}</div>
        </div>
      </div>

      <OTPInput
        value={OTP}
        onChange={setOTP}
        autoFocus
        OTPLength={4}
        otpType="number"
        disabled={loading}
        secure
        className="mt-6 justify-center gap-4 [&>input]:!mr-0 [&>input]:border border-gray-300 [&>input]:focus:border-primary [&>input]:rounded-lg [&>input]:!w-14 [&>input]:!h-16  [&>input]:text-3xl"
      />
      <ResendOTP
        className={`mt-8 flex-row-reverse !justify-end gap-1 text-sm [&>span]:text-gray-400 ${
          isTimerComplete ? "[&>span]:hidden" : ""
        }`}
        onTimerComplete={() => setIsTimerComplete(true)}
        onResendClick={() => {
          setIsTimerComplete(false);
          getOTP();
        }}
        renderButton={(props: any) => (
          <button
            className={
              isTimerComplete
                ? "font-semibold text-primary"
                : "cursor-not-allowed text-gray-400"
            }
            {...props}
          >
            {isTimerComplete ? "Resend Code" : "Request a new code in "}
          </button>
        )}
      />
    </div>
  );
};

export default VerifyAccount;
