// App Types
export const SET_IS_AUTH = "SET_IS_AUTH";
export const SET_USER = "SET_USER";
export const SET_IS_POPUP_OPENED = "SET_IS_POPUP_OPENED";
export const SET_PACKAGES = "SET_PACKAGES";
export const SET_OPTIONS = "SET_OPTIONS";
export const SET_CUSTOM_SUCCESS_MESSAGE = "SET_CUSTOM_SUCCESS_MESSAGE";

// Modal Types
export const SET_IS_LOGIN_MODAL_OPENED = "SET_IS_LOGIN_MODAL_OPENED";
export const SET_IS_SIGN_UP_MODAL_OPENED = "SET_IS_SIGN_UP_MODAL_OPENED";
export const SET_IS_FORGET_PASSWORD_MODAL_OPENED =
  "SET_IS_FORGET_PASSWORD_MODAL_OPENED";
export const SET_IS_VERIFY_ACCOUNT_MODAL_OPENED =
  "SET_IS_VERIFY_ACCOUNT_MODAL_OPENED";
export const SET_IS_RESET_PASSWORD_MODAL_OPENED =
  "SET_IS_RESET_PASSWORD_MODAL_OPENED";
export const SET_IS_SUCCESS_MODAL_OPENED = "SET_IS_SUCCESS_MODAL_OPENED";
