import { Menu } from "lucide-react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Logo from "../../assets/media/svg/Wassapy-Logo.svg";
import { IState } from "../../models/store";
import ActionBtns from "./components/ActionBtns";
import MenuList from "./components/MenuList";
import SideBar from "./components/SideBar";

const TopBar = () => {
  const { options } = useSelector((state: IState) => state.appReducer);

  const isBeta = options.find((o) => o.name === "beta")?.option_value === "yes";
  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);

  return (
    <div className="h-[88px] bg-[#F8F8F8] shadow-[0_6px_16px_rgb(0,0,0,0.03)] flex flex-col justify-center">
      <div className="container flex items-center justify-between">
        <div className="flex items-center gap-5">
          <div
            className="lg:hidden cursor-pointer"
            onClick={() => setIsMenuOpened(true)}
          >
            <Menu className="text-gray-500" />
          </div>
          <Link to="/" className="relative">
            <img src={Logo} alt="Wassapy" className="h-8" />
            {isBeta && (
              <span className="text-xs text-gray-400 ml-2 font-bold absolute right-[-30px] px-2 py-1 rounded-2xl bottom-[-18px]">
                Beta
              </span>
            )}
          </Link>
        </div>

        <SideBar
          isMenuOpened={isMenuOpened}
          setIsMenuOpened={setIsMenuOpened}
        />

        <MenuList />
        <ActionBtns />
      </div>
    </div>
  );
};

export default TopBar;
