import { Fade, Zoom } from "react-reveal";

import AppScreen from "../../../assets/media/png/App-Screenshot.png";
import DownloadAppButton from "../../../components/DownloadAppButton";
import Welcoming from "./Welcoming";

const Hero = () => {
  return (
    <div className="flex flex-col items-center">
      <div className="container pt-16 flex flex-col items-center">
        <Fade delay={200}>
          <Welcoming />
        </Fade>

        <Fade delay={300}>
          <h1 className="text-black text-center lg:text-[70px] text-5xl font-bold mt-8">
            Let&apos;s Use <span className="text-primary">Wassapy</span> By{" "}
            <br className="md:inline-block hidden" /> Enjoying Convenience With
            Us
          </h1>
        </Fade>

        <DownloadAppButton />
      </div>

      <Zoom delay={400}>
        <img
          src={AppScreen}
          alt="App Screenshot"
          className="mt-14"
          loading="lazy"
        />
      </Zoom>
    </div>
  );
};

export default Hero;
