import api from ".";

export const packages = async () => {
  try {
    const response = await api().get("/api/packages");
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
};
