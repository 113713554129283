import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import styles from "../TopBar.module.css";

const MenuList = () => {
  return (
    <div className="lg:flex items-center gap-8 hidden">
      <NavLink to="/" className={styles.topbarLink}>
        Home
      </NavLink>
      <HashLink smooth to="/#features" className={styles.topbarLink}>
        Features
      </HashLink>
      <NavLink to="/prices" className={styles.topbarLink}>
        Pricing
      </NavLink>
      <NavLink to="/terms" className={styles.topbarLink}>
        Terms and Conditions
      </NavLink>
      <HashLink smooth to="/#contact" className={styles.topbarLink}>
        Contact Us
      </HashLink>
    </div>
  );
};

export default MenuList;
