import ContactUs from "../../components/ContactUs";
import Pricing from "../../components/Pricing";
import { features } from "../../data/features";
import Features from "./components/Features";
import Hero from "./components/Hero";
import JoinUs from "./components/JoinUs";
import SingleFeature from "./components/SingleFeature";

const Home = () => {
  return (
    <div>
      <Hero />
      <Features />

      {features.map((feature, i) => (
        <SingleFeature
          key={feature.title}
          feature={feature}
          reversed={Boolean(i % 2)}
        />
      ))}

      <Pricing />
      <JoinUs />
      <ContactUs />
    </div>
  );
};

export default Home;
