import { Route, Routes } from "react-router-dom";

import ProtectedRoute from "./guards/ProtectedRoute";
import useApp from "./hooks/useApp";
import useDesktopApp from "./hooks/useDesktopApp";
import useMainData from "./hooks/useMainData";
import useSession from "./hooks/useSession";
import NotFound from "./pages/404/NotFound";
import Home from "./pages/Home";
import Prices from "./pages/Prices";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Profile from "./pages/Profile";
import Redirect from "./pages/Redirect";
import RefundPolicy from "./pages/RefundPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import Layout from "./shared/Layout";
import Modals from "./shared/Modals";

const App = () => {
  useApp();
  useSession(true);
  useMainData();
  useDesktopApp();

  return (
    <div className="Wassapy">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="terms" element={<TermsAndConditions />} />
          <Route path="refund" element={<RefundPolicy />} />
          <Route path="privacy" element={<PrivacyPolicy />} />
          <Route path="prices" element={<Prices />} />
          <Route
            path="profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="process/success"
            element={
              <ProtectedRoute>
                <Redirect />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>

      <Modals />
    </div>
  );
};

export default App;
