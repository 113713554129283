import Cookies from "js-cookie";
import { FC, ReactNode, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";

import { setIsLoginModalOpened, setIsPopupOpened } from "../store/actions";
export interface IProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute: FC<IProtectedRouteProps> = ({ children }) => {
  const dispatch = useDispatch();
  const token = Cookies.get(process.env.REACT_APP_AUTH_COOKIE_NAME || "token");

  useEffect(() => {
    if (token) return;
    dispatch(setIsPopupOpened(true));
    dispatch(setIsLoginModalOpened(true));
  }, [token]);

  return <>{token ? children : <Navigate to="/" />}</>;
};

export default ProtectedRoute;
