import axios from "axios";
import Cookies from "js-cookie";

const api = () => {
  const UserToken = Cookies.get(
    process.env.REACT_APP_AUTH_COOKIE_NAME || "token"
  );

  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${UserToken}`,
    },
  });
};
export default api;
