import { useSelector } from "react-redux";
import { Fade } from "react-reveal";
import { Link } from "react-router-dom";

import { IState } from "../../models/store";
import { IPackage } from "../../models/subscription";
import PriceCard from "../PriceCard";

const Pricing = () => {
  const { packages, options } = useSelector(
    (state: IState) => state.appReducer
  );

  const isBeta = options.find((o) => o.name === "beta")?.option_value === "yes";

  const packagesWithoutFree = packages.filter((p) => p.price !== 0);
  const freePackages = packages.filter((p) => p.price === 0);

  return (
    <div className="container flex flex-col items-center py-14" id="pricing">
      <Fade bottom>
        <div className="px-8 py-3 bg-primary bg-opacity-10 rounded-[39px] justify-center items-center gap-2.5 inline-flex text-primary text-2xl font-normal">
          Pricing
        </div>

        <div className="text-center text-black text-[40px] font-bold mt-6">
          Cost-effective, full service, high security
        </div>

        <div className="max-w-[588px] text-center text-gray-500 text-xl font-normal mt-6">
          Choose the plan that works for you. All plans include a 7 day free
          trial.
        </div>
      </Fade>

      <div className="w-full grid lg:overflow-x-auto lg:overflow-y-hidden custom-scrollbar lg:p-8">
        <div className="lg:flex lg:gap-6 lg:justify-center w-full lg:nowrap lg:whitespace-nowrap mt-16">
          {isBeta && (
            <>
              {freePackages.map((price: IPackage, i: number) => (
                <div key={price.id} className="lg:mb-0 mb-6">
                  <PriceCard price={price} order={i} />
                </div>
              ))}
            </>
          )}
          {packagesWithoutFree.map((price: IPackage, i: number) => (
            <div key={price.id} className="lg:mb-0 mb-6">
              <PriceCard price={price} order={i} />
            </div>
          ))}
        </div>
      </div>

      <div className="text-gray-400 mt-16 md:text-xl text-center text-base">
        You got&nbsp;
        <span className="font-bold text-primary">updates and support</span>
        &nbsp;from the date of purchase. We offer 7 days Money Back Guarantee
        based on &nbsp;
        <Link to="/refund" className="font-bold text-primary">
          Refund Policy
        </Link>
        .
      </div>
    </div>
  );
};

export default Pricing;
