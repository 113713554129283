import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getUserFromToken } from "../api/auth.api";
import { setIsAuth, setUser } from "../store/actions";

const useSession = (runOnRender = false) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userData, setUserData] = useState<any>(null);

  const UserToken = Cookies.get(
    process.env.REACT_APP_AUTH_COOKIE_NAME || "token"
  );

  const fetchUser = async () => {
    if (!UserToken) return;

    const { success, data } = await getUserFromToken();

    if (!success) {
      Cookies.remove(process.env.REACT_APP_AUTH_COOKIE_NAME || "token");
      toast.error("Your session has expired. Please login again.");
      return navigate("/");
    }

    setUserData(data);

    dispatch(setIsAuth(true));
    dispatch(setUser(data));
  };

  useEffect(() => {
    if (runOnRender) fetchUser();
  }, [runOnRender]);

  return { userData, fetchUser };
};

export default useSession;
