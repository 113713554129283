import { useSelector } from "react-redux";

import { IState } from "../../../models/store";
import { formatDate } from "../../../utils/functions";

const Transactions = () => {
  const { user } = useSelector((state: IState) => state.appReducer);

  return (
    <div className="mt-20">
      <h1 className="text-black text-[40px] font-bold">Transactions</h1>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              {user?.transaction.length ? (
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-100">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-lg font-bold text-gray-900 sm:pl-6"
                      >
                        Name of Plan
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-lg font-bold text-gray-900"
                      >
                        Subscription Date
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-lg font-bold text-gray-900"
                      >
                        Status of Plan
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-lg font-bold text-gray-900"
                      >
                        Price
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-lg font-bold text-gray-900"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {user?.transaction.map((transaction) => (
                      <tr key={transaction.id}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-bold text-gray-900 sm:pl-6">
                          {transaction.subscription?.package.name ||
                            "Current Plan"}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-500">
                          {formatDate(transaction.createdAt)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-500">
                          {transaction.status !== "Paid" ? (
                            <span className="px-2 py-2 min-w-[85px] text-center inline-block text-sm leading-5 font-semibold rounded-md bg-red-100 text-red-800">
                              {transaction.status}
                            </span>
                          ) : (
                            <span className="px-2 py-2 min-w-[85px] text-center inline-block text-sm leading-5 font-semibold rounded-md bg-green-100 text-green-800">
                              {transaction.status}
                            </span>
                          )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-slate-500">
                          {transaction.subscription?.package.price || "----"}{" "}
                          USD
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6">
                          {transaction.status === "Failed" && (
                            <button
                              onClick={() =>
                                window.open("https://www.paypal.com/signin", "_blank")
                              }
                              className="h-8 px-8 py-[7.50px] bg-blue-600 hover:bg-blue-800 rounded border border-blue-600 hover:border-blue-800 justify-start items-center gap-1.5 inline-flex text-white"
                            >
                              Pay Now
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="flex justify-center items-center h-[150px] bg-slate-200 rounded-md">
                  <div className="text-slate-600 text-[30px] font-medium">
                    No Transactions
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transactions;
