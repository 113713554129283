import { Fade } from "react-reveal";

import BulkSendIcon from "../../../assets/media/svg/bulk-send.svg";
import ContactsIcon from "../../../assets/media/svg/contacts.svg";
import GroupIcon from "../../../assets/media/svg/groups.svg";

const Features = () => {
  return (
    <div className="container flex flex-col items-center py-14" id="features">
      <Fade bottom>
        <div className="px-8 py-3 bg-primary bg-opacity-10 rounded-[39px] justify-center items-center gap-2.5 inline-flex text-primary text-2xl font-normal">
          Features
        </div>

        <div className="text-center text-black text-[40px] font-bold mt-6">
          Why Wassapy is the Best?
        </div>

        <div className="max-w-[588px] text-center text-gray-500 text-xl font-normal mt-6">
          We offer a number of features that are not available in WhatsApp
          itself or other WhatsApp bulk sender platforms
        </div>
      </Fade>

      <Fade bottom delay={300}>
        <div className="grid lg:grid-cols-3 grid-cols-1 gap-6 w-full mt-10">
          <div className="rounded-2xl p-8 flex flex-col items-center bg-white w-full">
            <div className="w-24 h-24 flex justify-center items-center bg-primary rounded-full">
              <img src={BulkSendIcon} alt="Bulk Send" />
            </div>

            <div className="text-center text-black text-2xl font-bold mt-6">
              Bulk Send
            </div>

            <div className="mt-4 text-center text-zinc-500 text-base font-normal">
              This service allows you to send bulk messages to a group of people
              with one click. Just provide the list of numbers and write your
              message.
            </div>
          </div>
          <div className="rounded-2xl p-8 flex flex-col items-center bg-white w-full">
            <div className="w-24 h-24 flex justify-center items-center bg-primary rounded-full">
              <img src={ContactsIcon} alt="Bulk Send" />
            </div>

            <div className="text-center text-black text-2xl font-bold mt-6">
              Contacts
            </div>

            <div className="mt-4 text-center text-zinc-500 text-base font-normal">
              This service allows you to access and export your WhatsApp
              contacts as an Excel file
            </div>
          </div>
          <div className="rounded-2xl p-8 flex flex-col items-center bg-white w-full">
            <div className="w-24 h-24 flex justify-center items-center bg-primary rounded-full">
              <img src={GroupIcon} alt="Bulk Send" />
            </div>

            <div className="text-center text-black text-2xl font-bold mt-6">
              Groups
            </div>

            <div className="mt-4 text-center text-zinc-500 text-base font-normal">
              Need a large segment? access your groups and get all participents
              as Excel Files
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default Features;
