import { IAction, IAppState } from "@/models/store";

import {
  SET_CUSTOM_SUCCESS_MESSAGE,
  SET_IS_AUTH,
  SET_IS_POPUP_OPENED,
  SET_OPTIONS,
  SET_PACKAGES,
  SET_USER,
} from "../types";

const initialState = {
  isAuthorized: false,
  user: null,
  isPopupOpened: false,
  packages: [],
  options: [],
  customSuccessMessage: "",
};

const appReducer = (state: IAppState = initialState, action: IAction) => {
  switch (action.type) {
    case SET_IS_AUTH:
      return {
        ...state,
        isAuthorized: action.payload,
      };
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case SET_IS_POPUP_OPENED:
      return {
        ...state,
        isPopupOpened: action.payload,
      };
    case SET_PACKAGES:
      return {
        ...state,
        packages: action.payload,
      };
    case SET_OPTIONS:
      return {
        ...state,
        options: action.payload,
      };
    case SET_CUSTOM_SUCCESS_MESSAGE:
      return {
        ...state,
        customSuccessMessage: action.payload,
      };
    default:
      return state;
  }
};

export default appReducer;
