import { FC } from "react";
import { Fade, Zoom } from "react-reveal";

import { ISingleFeatureProps } from "@/models/features";

const SingleFeature: FC<ISingleFeatureProps> = ({ feature, reversed }) => {
  return (
    <div className="container lg:py-16 py-10 grid lg:grid-cols-2 gap-10">
      <div className="flex justify-center flex-col">
        <Fade top cascade>
          <div className="flex items-center gap-4 font-bold text-4xl">
            <div
              className={`w-12 h-12 flex justify-center items-center rounded-full p-3 ${
                feature.iconBG ? "bg-primary" : ""
              }`}
            >
              <img src={feature.icon} alt={feature.title} />
            </div>
            {feature.title}
          </div>
          <p className="text-black text-lg font-bold mt-7">
            {feature.description}
          </p>
          <ul>
            {feature.list.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </Fade>
      </div>
      <div className={`flex items-center ${reversed ? "lg:-order-1" : ""}`}>
        <Zoom>
          <img
            src={feature.image}
            alt={feature.title}
            className="max-w-full drop-shadow-lg"
          />
        </Zoom>
      </div>
    </div>
  );
};

export default SingleFeature;
