import { Loader2 } from "lucide-react";
import { FC, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";

import { register } from "../../api/auth.api";
import Logo from "../../assets/media/svg/Wassapy-Logo.svg";
import Input from "../../shared/Form/Input";
import {
  setIsSignUpModalOpened,
  setIsVerifyAccountModalOpened,
  setUser,
} from "../../store/actions";

export interface ISignUpProps {
  onLogin: () => void;
}

const SignUp: FC<ISignUpProps> = ({ onLogin }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const handleSignUp = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    // Validation
    if (formData.password !== formData.confirmPassword) {
      toast.error("Passwords Don't Match");
      return setLoading(false);
    }

    const fields = Object.values(formData);
    if (fields.some((field) => field === "")) {
      toast.error("Please Fill All Fields");
      return setLoading(false);
    }

    const { name, phoneNumber, email, password } = formData;
    const { success, errors, data } = await register({
      name,
      phoneNumber,
      email,
      password,
    });

    setLoading(false);

    if (!success) return errors.forEach((error: string) => toast.error(error));

    dispatch(setUser(data));
    dispatch(setIsSignUpModalOpened(false));
    dispatch(setIsVerifyAccountModalOpened(true));

    toast.success("Account Created Successfully");
  };

  return (
    <div className="max-w-2xl bg-white my-[88px] mx-auto lg:px-24 px-12 py-14 rounded-lg overflow-auto">
      <img src={Logo} alt="Wassapy" className="h-10" />
      <div className="mt-8">
        <div className="text-black lg:text-4xl text-2xl font-black">
          Welcome,
        </div>
        <div className="text-black lg:text-xl text-base font-normal mt-4">
          Welcome! Please enter your details.
        </div>
      </div>

      <form className="mt-6" onSubmit={handleSignUp}>
        <Input
          value={formData.name}
          onTextChange={(value) =>
            setFormData({
              ...formData,
              name: value,
            })
          }
          placeholder="Full Name"
          disabled={loading}
        />
        <Input
          value={formData.phoneNumber}
          className="mt-4"
          onTextChange={(value) =>
            setFormData({
              ...formData,
              phoneNumber: value,
            })
          }
          type="tel"
          placeholder="Phone Number"
          disabled={loading}
        />
        <Input
          value={formData.email}
          className="mt-4"
          onTextChange={(value) =>
            setFormData({
              ...formData,
              email: value,
            })
          }
          type="email"
          placeholder="Email"
          disabled={loading}
        />
        <Input
          value={formData.password}
          className="mt-4"
          onTextChange={(value) =>
            setFormData({
              ...formData,
              password: value,
            })
          }
          type="password"
          placeholder="Password"
          disabled={loading}
        />
        <Input
          value={formData.confirmPassword}
          className="mt-4"
          onTextChange={(value) =>
            setFormData({
              ...formData,
              confirmPassword: value,
            })
          }
          type="password"
          placeholder="Confirm Password"
          disabled={loading}
        />
        <button
          type="submit"
          className="mt-10 mx-auto inline-flex items-center text-white px-8 py-[14.50px] text-lg font-bold w-full bg-primary active:bg-primary-dimmed hover:bg-primary-dimmed rounded-[50px] justify-center gap-2.5"
        >
          {loading ? <Loader2 className="animate-spin" /> : "Sign Up"}
        </button>

        <div>
          <div className="text-black text-base font-normal mt-7 text-center">
            Have an account? &nbsp;
            <span
              className="text-primary cursor-pointer underline"
              onClick={() => {
                onLogin();
              }}
            >
              Log In
            </span>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SignUp;
