import { Facebook, Linkedin, Twitter } from "lucide-react";
import { Link } from "react-router-dom";

import Logo from "../../assets/media/svg/Wassapy-Logo.svg";

const Footer = () => {
  return (
    <div className="bg-white border-t-2 border-gray-200">
      <div className="container flex items-center lg:flex-row flex-col gap-5 justify-between lg:h-24 lg:py-0 py-5">
        <Link to="/">
          <img className="h-10" src={Logo} alt="Wassapy Logo" />
        </Link>

        <div className="flex lg:flex-row flex-col items-center gap-3">
          <div className="text-slate-900 text-lg font-semibold">
            &copy; Wassapy 2023. All Rights Reserved.
          </div>
          <div className="lg:block hidden">|</div>
          <div className="flex gap-2 items-center">
            <Link className="text-primary font-medium underline" to="privacy">
              Privacy Policy
            </Link>
            <Link className="text-primary font-medium underline" to="refund">
              Refund Policy
            </Link>
          </div>
        </div>

        <div className="flex items-center space-x-4">
          <a
            className="w-10 h-10 flex items-center justify-center rounded-full border border-gray-500"
            href="https://www.facebook.com/wassapy"
            target="_blank"
            rel="noreferrer"
          >
            <Facebook className="w-6 h-6 text-gray-500" />
          </a>
          <a
            className="w-10 h-10 flex items-center justify-center rounded-full border border-gray-500"
            href="https://www.linkedin.com/company/wassapy-app"
            target="_blank"
            rel="noreferrer"
          >
            <Linkedin className="w-6 h-6 text-gray-500" />
          </a>
          {/* <a
            className="w-10 h-10 flex items-center justify-center rounded-full border border-gray-500"
            href="https://twitter.com/wassapy"
            target="_blank"
            rel="noreferrer"
          >
            <Twitter className="w-6 h-6 text-gray-500" />
          </a> */}
        </div>
      </div>
    </div>
  );
};

export default Footer;
