import api from ".";

export const contactUs = async (
  name: string,
  email: string,
  message: string
) => {
  try {
    const response = await api().post("/api/management/contact-us", {
      name,
      email,
      message,
    });
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
};
