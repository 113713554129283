import { Loader2 } from "lucide-react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { cancelSubscription } from "../../../api/subscription.api";
import { IState } from "../../../models/store";
import {
  setIsPopupOpened,
  setIsVerifyAccountModalOpened,
} from "../../../store/actions";
import { formatDate } from "../../../utils/functions";

const UserInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state: IState) => state.appReducer);
  const [loading, setLoading] = useState(false);

  const handleCancelSubscription = async () => {
    if (!user?.subscription[user?.subscription.length - 1])
      return toast.error("You are not subscribed to any plan");

    setLoading(true);

    const { success, errors, message } = await cancelSubscription(
      user.subscription[0].id
    );

    setLoading(false);

    if (!success) return errors.forEach((error: string) => toast.error(error));

    navigate("/process/success");

    toast.success(message);
  };

  return (
    <>
      <div className="max-w-[773px] mx-auto rounded-lg bg-white p-10 w-full flex flex-col justify-center items-center">
        <div className="relative">
          {!user?.isVerified && (
            <>
              <div className="bg-red-100 border border-red-400 text-red-700 px-2 py-1 rounded absolute text-xs top-2 -left-2 inline-block w-fit">
                Not Verified
              </div>

              <div
                onClick={() => {
                  dispatch(setIsPopupOpened(true));
                  dispatch(setIsVerifyAccountModalOpened(true));
                }}
                className="text-gray-700 px-2 py-1 rounded absolute text-sm top-1/2 -translate-x-1/2 -right-24 inline-block w-fit underline cursor-pointer"
              >
                Verify ?
              </div>
            </>
          )}
          <img
            src={`${process.env.REACT_APP_API_URL}${user?.profilePicture}`}
            className="w-36 h-36 rounded-full"
            alt="Hamada"
          />
        </div>
        <div className="text-2xl text-black font-bold text-center px-5 mt-6">
          {user?.name}
        </div>
        <div className="grid lg:grid-cols-12 grid-cols-1 mt-8 w-full gap-3">
          <div className="flex flex-col gap-3 col-span-5">
            <div className="text-slate-400 text-xs font-normal">
              Email Address
            </div>
            <div className="text-black text-base font-medium">
              {user?.email}
            </div>
          </div>
          <div className="col-span-7 grid md:grid-cols-3 grid-cols-2 gap-3">
            <div className="flex flex-col gap-3">
              <div className="text-slate-400 text-xs font-normal">
                Your Plan
              </div>
              <div className="text-black text-base font-medium">
                {user?.subscription[user?.subscription.length - 1]?.package
                  ?.name || "No Plan"}
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <div className="text-slate-400 text-xs font-normal">
                Status of Plan
              </div>
              {!user?.subscription[user?.subscription.length - 1] ? (
                <div className="text-gray-500 text-base font-medium">
                  Not Subscribed
                </div>
              ) : (
                <div
                  className={`${
                    user?.transaction[user?.transaction.length - 1]?.status ===
                    "Paid"
                      ? "text-primary"
                      : "text-red-800"
                  } text-base font-medium`}
                >
                  {user?.transaction[user?.transaction.length - 1]?.status}
                </div>
              )}
            </div>
            <div className="flex flex-col gap-3">
              <div className="text-slate-400 text-xs font-normal">
                Renewal Date
              </div>
              <div className="text-black text-base font-medium">
                {user?.subscription[user?.subscription.length - 1]?.renewalDate
                  ? formatDate(
                      user?.subscription[user?.subscription.length - 1]
                        ?.renewalDate
                    )
                  : "N/A"}
              </div>
            </div>
          </div>
        </div>
      </div>

      {user?.subscription[user?.subscription.length - 1] &&
        user?.subscription[user?.subscription.length - 1].package.price !==
          0 && (
          <div
            className="text-red-700 text-sm underline table mx-auto mt-4 cursor-pointer"
            onClick={handleCancelSubscription}
          >
            {loading ? (
              <Loader2 className="animate-spin" />
            ) : (
              "Cancel Subscription"
            )}
          </div>
        )}
    </>
  );
};

export default UserInfo;
