import { Loader2 } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import SuccessfullyImage from "../../assets/media/svg/Successful-purchase-pana.svg";
import useSession from "../../hooks/useSession";

const Redirect = () => {
  const navigate = useNavigate();
  const { fetchUser } = useSession();

  const [seconds, setSeconds] = useState(20);
  const [loading, setLoading] = useState(false);
  const [timerId, setTimerId] = useState<NodeJS.Timer>();

  const countDown = () => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds - 1);
    }, 1000);

    setTimerId(interval);
  };

  useEffect(() => {
    if (!timerId) countDown();

    if (seconds === 0) {
      console.log("Redirecting...");

      clearInterval(timerId);
      setLoading(true);
      fetchUser();
      setLoading(false);

      navigate("/profile");
    }
  }, [seconds]);

  return (
    <div className="container py-14 min-h-[calc(100vh-314px)] lg:min-h-[calc(100vh-186px)]">
      <div className="text-4xl text-center font-bold">
        🚀 Thanks For Subscribing In Wassapy!
      </div>
      <div className="text-center mt-2 text-gray-500 text-lg">
        Your Payment Was Successful
      </div>
      <div className="text-center mt-2 text-gray-500 text-lg">
        Sometimes, it takes 5-10 minutes to activate your subscription
      </div>

      <div className="text-primary font-semibold text-center mt-8 text-xl flex items-center justify-center">
        You will be redirected to your profile in (
        {seconds > 0 ? (
          seconds
        ) : loading ? (
          <Loader2 className="animate-spin" />
        ) : (
          0
        )}
        ) seconds
      </div>

      <div className="flex justify-center mt-5 mb-9">
        <img
          src={SuccessfullyImage}
          className="max-w-xl w-full px-6"
          alt="Successfully"
        />
      </div>
    </div>
  );
};

export default Redirect;
