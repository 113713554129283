import { FC, ReactNode } from "react";

export interface ITextPageProps {
  children: ReactNode;
}

const TextPage: FC<ITextPageProps> = ({ children }) => {
  return (
    <div
      className="[&>p]:mb-4 [&>p]:text-gray-500 [&_a]:text-primary [&_a]:font-medium [&_a]:underline [&_li]:text-gray-600 [&_li]:font-medium
        [&>h1]:font-bold [&>h2]:font-bold [&>h3]:font-bold [&>h4]:font-bold [&>h5]:font-bold [&>h6]:font-bold
        [&>h1]:text-4xl [&>h2]:text-3xl [&>h3]:text-2xl [&>h4]:text-xl [&>h5]:text-lg [&>h6]:text-base
        [&>h1]:mb-4 [&>h2]:mb-4 [&>h3]:mb-4 [&>h4]:mb-4 [&>h5]:mb-4 [&>h6]:mb-4
        [&_li_p]:"
    >
      {children}
    </div>
  );
};

export default TextPage;
