import { IUser } from "@/models/user";

import {
  SET_CUSTOM_SUCCESS_MESSAGE,
  SET_IS_AUTH,
  SET_IS_FORGET_PASSWORD_MODAL_OPENED,
  SET_IS_LOGIN_MODAL_OPENED,
  SET_IS_POPUP_OPENED,
  SET_IS_RESET_PASSWORD_MODAL_OPENED,
  SET_IS_SIGN_UP_MODAL_OPENED,
  SET_IS_SUCCESS_MODAL_OPENED,
  SET_IS_VERIFY_ACCOUNT_MODAL_OPENED,
  SET_OPTIONS,
  SET_PACKAGES,
  SET_USER,
} from "./types";

// App Actions
export const setIsAuth = (payload: boolean) => ({
  type: SET_IS_AUTH,
  payload,
});

export const setUser = (payload: IUser | null) => ({
  type: SET_USER,
  payload,
});

export const setIsPopupOpened = (payload: boolean) => ({
  type: SET_IS_POPUP_OPENED,
  payload,
});

export const setPackages = (payload: any) => ({
  type: SET_PACKAGES,
  payload,
});

export const setOptions = (payload: any) => ({
  type: SET_OPTIONS,
  payload,
});

export const setCustomSuccessMessage = (payload: string) => ({
  type: SET_CUSTOM_SUCCESS_MESSAGE,
  payload,
});

// Modal Actions
export const setIsLoginModalOpened = (payload: boolean) => ({
  type: SET_IS_LOGIN_MODAL_OPENED,
  payload,
});

export const setIsSignUpModalOpened = (payload: boolean) => ({
  type: SET_IS_SIGN_UP_MODAL_OPENED,
  payload,
});

export const setIsForgetPasswordModalOpened = (payload: boolean) => ({
  type: SET_IS_FORGET_PASSWORD_MODAL_OPENED,
  payload,
});

export const setIsVerifyAccountModalOpened = (payload: boolean) => ({
  type: SET_IS_VERIFY_ACCOUNT_MODAL_OPENED,
  payload,
});

export const setIsResetPasswordModalOpened = (payload: boolean) => ({
  type: SET_IS_RESET_PASSWORD_MODAL_OPENED,
  payload,
});

export const setIsSuccessModalOpened = (payload: boolean) => ({
  type: SET_IS_SUCCESS_MODAL_OPENED,
  payload,
});
