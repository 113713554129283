import { FC, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { IState } from "@/models/store";

export interface IPaypalCheckoutProps {
  planId: string;
}

const PaypalCheckout: FC<IPaypalCheckoutProps> = ({ planId }) => {
  const navigate = useNavigate();
  const { user } = useSelector((state: IState) => state.appReducer);
  const paypalButtons = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    paypal
      .Buttons({
        createSubscription: (data: any, actions: any) => {
          return actions.subscription.create({
            plan_id: planId,
            subscriber: {
              email_address: user?.email,
            },
            custom_id: user?.id,
          });
        },
        onApprove: () => {
          navigate("/process/success");
        },
      })
      .render(paypalButtons.current);
  }, []);

  return (
    <div className="max-w-2xl bg-white my-[80px] mx-auto lg:px-24 px-8 py-14 rounded-lg overflow-auto flex flex-col items-center">
      <div className="text-black lg:text-3xl text-2xl font-black">
        Proceed to Checkout
      </div>

      <div className="w-full mt-8" ref={paypalButtons}></div>
    </div>
  );
};

export default PaypalCheckout;
