import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";

import { options } from "../api/options.api";
import { packages } from "../api/package.api";
import { setOptions,setPackages } from "../store/actions";

const useMainData = () => {
  const [packagesData, setPackagesData] = useState([]);
  const [optionsData, setOptionsData] = useState([]);

  const dispatch = useDispatch();

  const getPackage = async () => {
    const packagesData = await packages();
    const optionsData = await options();

    if (!packagesData.success)
      return packagesData.errors.forEach((error: string) => toast.error(error));
    if (!optionsData.success)
      return optionsData.errors.forEach((error: string) => toast.error(error));

    dispatch(setOptions(optionsData.data));
    setOptionsData(optionsData.data);

    dispatch(setPackages(packagesData.data));
    setPackagesData(packagesData.data);
  };

  useEffect(() => {
    getPackage();
  }, []);

  return { packagesData, optionsData };
};

export default useMainData;
