import { Loader2 } from "lucide-react";
import { useState } from "react";
import { toast } from "react-hot-toast";

import { contactUs } from "../../api/management.api";

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleContactUs = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);
    const { success, errors, message } = await contactUs(
      formData.name,
      formData.email,
      formData.message
    );
    setLoading(false);

    if (!success) return errors.forEach((error: string) => toast.error(error));

    toast.success(message);
  };

  return (
    <div className="container py-14" id="contact">
      <div className="font-bold text-center lg:text-5xl md:text-4xl text-3xl leading-normal">
        Want to get in touch? <br /> Drop us a line
      </div>

      <form
        className="max-w-4xl grid md:grid-cols-2 mt-11 mx-auto gap-5"
        onSubmit={handleContactUs}
      >
        <div className="flex flex-col gap-2">
          <label className="text-zinc-500 text-2xl font-normal">Name</label>
          <input
            type="text"
            className="h-14 bg-white rounded-lg border border-gray-200 px-4"
            placeholder="Enter your name"
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            disabled={loading}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-zinc-500 text-2xl font-normal">
            Email Address
          </label>
          <input
            type="text"
            className="h-14 bg-white rounded-lg border border-gray-200 px-4"
            placeholder="Enter your email"
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
            disabled={loading}
          />
        </div>
        <div className="flex flex-col gap-2 md:col-span-2 mt-3">
          <label className="text-zinc-500 text-2xl font-normal">Message</label>
          <textarea
            placeholder="Enter your message"
            className="bg-white rounded-lg border border-gray-200 p-4"
            rows={5}
            onChange={(e) =>
              setFormData({ ...formData, message: e.target.value })
            }
            disabled={loading}
          />
        </div>

        <div />
        <div className="flex justify-end">
          <button
            className="bg-primary text-white px-8 py-3 w-1/2 rounded-3xl mt-6 flex justify-center items-center"
            disabled={loading}
          >
            {loading ? <Loader2 className="animate-spin" /> : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactUs;
