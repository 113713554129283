import Lottie from "lottie-react";
import { Link } from "react-router-dom";

import NotFound_404 from "../../assets/media/lottie/lonely-404.json";

const NotFound = () => {
  return (
    <div className="h-[calc(100vh-186px)] flex flex-col justify-center items-center">
      <Lottie
        animationData={NotFound_404}
        loop={true}
        style={{
          height: "300px",
        }}
      />

      <Link
        to="/"
        className="h-10 inline-flex justify-center items-center px-8 text-white rounded-[50px] border active:border-primary-dimmed hover:border-primary-dimmed border-primary bg-primary active:bg-primary-dimmed hover:bg-primary-dimmed font-medium text-base"
      >
        Go Home
      </Link>
    </div>
  );
};

export default NotFound;
