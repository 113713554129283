import { Check, Loader2 } from "lucide-react";
import { FC, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Fade } from "react-reveal";
import { useNavigate } from "react-router-dom";

import { createSubscription } from "../../api/subscription.api";
import { IPriceCardProps } from "../../models/prices";
import { IState } from "../../models/store";
import Popup from "../../shared/Popup";
import { setIsLoginModalOpened, setIsPopupOpened } from "../../store/actions";
import PaypalCheckout from "../PaypalCheckout";

const PriceCard: FC<IPriceCardProps> = ({ price, order, horizontal }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, isAuthorized, options } = useSelector(
    (state: IState) => state.appReducer
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isCheckoutPopupOpened, setIsCheckoutPopupOpened] = useState(false);
  const [currentPlanId, setCurrentPlanId] = useState("");

  const isBeta = options.find((o) => o.name === "beta")?.option_value === "yes";

  const handleCheckouts = async () => {
    if (isBeta && price.price !== 0) return toast.error("We Are In Beta!");

    if (!isAuthorized) {
      dispatch(setIsPopupOpened(true));
      dispatch(setIsLoginModalOpened(true));
      return;
    }

    if (user?.isActive) return toast.success("You Already Subscribed!");

    if (price.price === 0 && user) {
      setIsLoading(true);

      const { success, errors } = await createSubscription(
        user.id,
        Number(price.id)
      );

      setIsLoading(false);

      if (!success)
        return errors.forEach((error: string) => toast.error(error));

      return navigate("/process/success");
    }

    setIsCheckoutPopupOpened(true);
    setCurrentPlanId(price.paypalIdentifier);
  };

  return (
    <div className={`${horizontal ? "" : "lg:max-w-[309px]"}`}>
      <Fade bottom key={price.name} delay={order * 100}>
        <div>
          <div
            className={`px-4 py-9 rounded-xl shadow-[0_6px_16px_rgba(0,0,0,0.15)] transition-all duration-200 hover:scale-105 ${
              order === 1 ? "bg-primary text-white" : "bg-white"
            }
          ${horizontal ? "lg:grid lg:grid-cols-2 lg:gap-7" : ""}`}
          >
            <div>
              <h1
                className={`${
                  order === 1 ? "text-white" : "text-primary"
                } text-[40px] font-bold`}
              >
                {price.name}
              </h1>
              <div
                className={`${
                  order === 1 ? "text-white" : "text-primary"
                } font-normal text-base mt-6 flex items-center gap-2`}
              >
                <span className="text-[56px]">${price.price}</span>/{" "}
                {price.duration} {price.duration === 1 ? "Month" : "Months"}
              </div>

              <button
                className={`${
                  order === 1
                    ? "bg-white text-primary hover:text-white"
                    : "bg-primary text-white"
                } w-full rounded-3xl  py-3 mt-14 hover:bg-primary-dimmed flex items-center justify-center`}
                onClick={handleCheckouts}
              >
                {price.price !== 0 ? (
                  "Get Started Now"
                ) : isLoading ? (
                  <Loader2 className="animate-spin" />
                ) : (
                  "Get Started Now"
                )}
              </button>
            </div>

            <div className={`${horizontal ? "mt-10 lg:mt-0" : "mt-10"}`}>
              {price.description.split(",").map((line) => (
                <div
                  className={`${
                    order === 1 ? "text-white" : "text-primary"
                  } flex gap-2 mt-4`}
                  key={line}
                >
                  <Check
                    className={`${
                      order === 1
                        ? "text-primary bg-white"
                        : "text-white bg-primary"
                    } w-6 h-6 rounded-full p-1`}
                  />{" "}
                  {line}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Fade>

      {isCheckoutPopupOpened && (
        <Popup onClose={() => setIsCheckoutPopupOpened(false)}>
          <PaypalCheckout planId={currentPlanId} />
        </Popup>
      )}
    </div>
  );
};

export default PriceCard;
