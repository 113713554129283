import { Loader2 } from "lucide-react";
import { FC, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

import { resetPassword } from "../../api/auth.api";
import Logo from "../../assets/media/svg/Wassapy-Logo.svg";
import { IState } from "../../models/store";
import Input from "../../shared/Form/Input";
import {
  setCustomSuccessMessage,
  setIsSuccessModalOpened,
} from "../../store/actions";

export interface IResetPasswordProps {
  onDone: () => void;
}

const ResetPassword: FC<IResetPasswordProps> = ({ onDone }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: IState) => state.appReducer);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });

  const handleResetPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword)
      return toast.error("Passwords do not match.");

    setLoading(true);
    const { success, errors, message } = await resetPassword(
      formData.password,
      user?.id as number
    );
    setLoading(false);

    if (!success) return errors.map((error: string) => toast.error(error));

    toast.success(message);
    dispatch(setIsSuccessModalOpened(true));
    dispatch(setCustomSuccessMessage("Password reset successfully."));

    onDone();
  };

  return (
    <div className="max-w-2xl bg-white my-[88px] mx-auto lg:px-24 px-12 py-14 rounded-lg overflow-auto">
      <img src={Logo} alt="Wassapy" className="h-10" />
      <div className="mt-8">
        <div className="text-black lg:text-4xl text-2xl font-black">
          Reset Password
        </div>
        <div className="text-black lg:text-xl text-base font-normal mt-4">
          Please enter your new password.
        </div>
      </div>

      <form className="mt-6" onSubmit={handleResetPassword}>
        <Input
          value={formData.password}
          className="mt-4"
          onTextChange={(value) =>
            setFormData({
              ...formData,
              password: value,
            })
          }
          type="password"
          placeholder="Password"
          disabled={loading}
        />
        <Input
          value={formData.confirmPassword}
          className="mt-4"
          onTextChange={(value) =>
            setFormData({
              ...formData,
              confirmPassword: value,
            })
          }
          type="password"
          placeholder="Confirm Password"
          disabled={loading}
        />
        <button
          type="submit"
          className="mt-10 mx-auto inline-flex items-center text-white px-8 py-[14.50px] text-lg font-bold w-full bg-primary active:bg-primary-dimmed hover:bg-primary-dimmed rounded-[50px] justify-center gap-2.5"
        >
          {loading ? <Loader2 className="animate-spin" /> : "Submit"}
        </button>
      </form>
    </div>
  );
};

export default ResetPassword;
