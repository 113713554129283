import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ForgetPassword from "../../components/ForgetPassword";
import Login from "../../components/Login";
import ResetPassword from "../../components/ResetPassword";
import SignUp from "../../components/SignUp";
import Success from "../../components/Success";
import VerifyAccount from "../../components/VerifyAccount";
import { IState } from "../../models/store";
import {
  setCustomSuccessMessage,
  setIsForgetPasswordModalOpened,
  setIsLoginModalOpened,
  setIsPopupOpened,
  setIsSignUpModalOpened,
  setIsSuccessModalOpened,
  setIsVerifyAccountModalOpened,
} from "../../store/actions";
import Popup from "../Popup";

const Modals = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const modals = useSelector((state: IState) => state.modalReducer);
  const { isPopupOpened, customSuccessMessage } = useSelector(
    (state: IState) => state.appReducer
  );
  const [verifyType, setVerifyType] = React.useState<
    "verify-account" | "forget"
  >("verify-account");

  return (
    <>
      {isPopupOpened && (
        <Popup onClose={() => dispatch(setIsPopupOpened(false))}>
          {modals.isLoginModalOpened && (
            <Login
              onSignUp={() => dispatch(setIsSignUpModalOpened(true))}
              onForgetPassword={() =>
                dispatch(setIsForgetPasswordModalOpened(true))
              }
            />
          )}
          {modals.isSignUpModalOpened && (
            <SignUp onLogin={() => dispatch(setIsLoginModalOpened(true))} />
          )}

          {modals.isForgetPasswordModalOpened && (
            <ForgetPassword
              onVerify={() => {
                setVerifyType("forget");
                dispatch(setIsVerifyAccountModalOpened(true));
              }}
            />
          )}

          {modals.isVerifyAccountModalOpened && (
            <VerifyAccount
              onDone={() => {
                dispatch(setIsVerifyAccountModalOpened(false));
                dispatch(setIsSuccessModalOpened(true));
                dispatch(
                  setCustomSuccessMessage("Account verified Successfully")
                );
                navigate("/prices");
              }}
              type={verifyType}
            />
          )}

          {modals.isResetPasswordModalOpened && (
            <ResetPassword onDone={() => null} />
          )}

          {modals.isSuccessModalOpened && (
            <Success message={customSuccessMessage} />
          )}
        </Popup>
      )}
    </>
  );
};

export default Modals;
