import api from ".";

export const createSubscription = async (userId: number, packageId: number) => {
  try {
    const response = await api().post("/api/subscriptions", {
      userId,
      packageId,
    });
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
};

export const cancelSubscription = async (subscriptionId: number) => {
  try {
    const response = await api().post("/api/subscriptions/cancel", {
      subscriptionId,
    });
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
};
