import Cookies from "js-cookie";
import { LogOut, User } from "lucide-react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { IState } from "../../../models/store";
import Popup from "../../../shared/Popup";
import {
  setIsAuth,
  setIsLoginModalOpened,
  setIsPopupOpened,
  setIsSignUpModalOpened,
  setUser,
} from "../../../store/actions";

const ActionBtns = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthorized, user } = useSelector(
    (state: IState) => state.appReducer
  );

  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);
  const [isConfirmOpened, setIsConfirmOpened] = useState<boolean>(false);

  const handleLogout = () => {
    Cookies.remove(process.env.REACT_APP_AUTH_COOKIE_NAME || "token");
    dispatch(setUser(null));
    dispatch(setIsAuth(false));
    navigate("/");
  };

  return (
    <>
      {isAuthorized && user ? (
        <div className="lg:flex items-center hidden relative">
          <div
            className="flex items-center cursor-pointer gap-2 p-2 hover:bg-primary hover:bg-opacity-5 rounded-md mr-1 transition-all duration-200"
            onClick={() => setIsMenuOpened(!isMenuOpened)}
          >
            <div className="w-10 h-10 bg-primary bg-opacity-10 rounded-full flex items-center justify-center">
              <User className="text-primary" />
            </div>
            <div className="text-black text-md font-medium">{user.name}</div>
          </div>

          {isMenuOpened && (
            <div className="p-3 bdiv#F8F8F8] rounded-b-md absolute top-[70px] shadow-[0_12px_16px_rgb(0,0,0,0.1)] w-[170px] right-0">
              <Link
                to="/profile"
                onClick={() => setIsMenuOpened(false)}
                className="flex p-3 rounded-md text-gray-400 gap-2 items-center parent text-sm font-medium hover:bg-primary hover:bg-opacity-5 hover:text-primary"
              >
                <User /> My Profile
              </Link>
              <span
                className="flex p-3 rounded-md text-gray-400 gap-2 cursor-pointer items-center parent text-sm font-medium hover:bg-primary hover:bg-opacity-5 hover:text-primary"
                onClick={() => {
                  setIsConfirmOpened(true);
                  setIsMenuOpened(false);
                }}
              >
                <LogOut /> Logout
              </span>
            </div>
          )}
        </div>
      ) : (
        <div className="lg:flex gap-2 hidden">
          <button
            className="text-primary h-10 px-8 bg-[#F8F8F8] rounded-[50px] border border-primary font-medium active:text-white active:bg-primary hover:bg-primary hover:text-white text-base"
            onClick={() => {
              dispatch(setIsPopupOpened(true));
              dispatch(setIsLoginModalOpened(true));
            }}
          >
            Log In
          </button>
          <button
            className="h-10 px-8 text-white rounded-[50px] border active:border-primary-dimmed hover:border-primary-dimmed border-primary bg-primary active:bg-primary-dimmed hover:bg-primary-dimmed font-medium text-base"
            onClick={() => {
              dispatch(setIsPopupOpened(true));
              dispatch(setIsSignUpModalOpened(true));
            }}
          >
            Sign Up
          </button>
        </div>
      )}

      {isConfirmOpened && (
        <Popup onClose={() => setIsConfirmOpened(false)}>
          <div className="max-w-2xl bg-white my-[88px] mx-auto lg:px-24 px-12 py-14 rounded-lg overflow-auto">
            <div className="flex justify-center">
              <LogOut size={100} className="text-primary" />
            </div>
            <div className="text-black lg:text-3xl text-xl font-black text-center mt-4">
              Are you sure you want to logout?
            </div>

            <div className="flex justify-center mt-8">
              <button
                className="h-10 px-8 text-white rounded-[50px] border active:border-primary-dimmed hover:border-primary-dimmed border-primary bg-primary active:bg-primary-dimmed hover:bg-primary-dimmed font-medium text-base"
                onClick={() => {
                  handleLogout();
                  setIsConfirmOpened(false);
                }}
              >
                Yes
              </button>
              <button
                className="text-primary h-10 px-8 bg-[#fff] rounded-[50px] border border-primary font-medium active:text-white active:bg-primary hover:bg-primary hover:text-white text-base ml-4"
                onClick={() => setIsConfirmOpened(false)}
              >
                No
              </button>
            </div>
          </div>
        </Popup>
      )}
    </>
  );
};

export default ActionBtns;
