import "./styles/index.css";

import ReactDOM from "react-dom/client";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import App from "./App";
import store from "./store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>

    <Toaster
      toastOptions={{
        duration: 3000,
        position: "bottom-right",
      }}
    />
  </Provider>
);
