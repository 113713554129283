import { Eye, EyeOff } from "lucide-react";
import { FC, useState } from "react";

export interface IInputProps {
  placeholder: string;
  value: string;
  onTextChange: (value: string) => void;
  type?: string;
  className?: string;
  disabled?: boolean;
}

const Input: FC<IInputProps> = ({
  onTextChange,
  placeholder,
  value,
  type = "text",
  className,
  disabled = false,
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  return (
    <div className={`relative ${className}`}>
      <input
        className="peer h-14 w-full border-b border-blue-gray-200 border-t-transparent bg-transparent pt-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-b focus:border-b-2 focus:border-primary focus:border-t-transparent focus:outline-0 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed"
        placeholder=" "
        onChange={(e) => onTextChange(e.target.value)}
        value={value}
        type={isPasswordVisible ? "text" : type}
        disabled={disabled}
      />
      {type === "password" && (
        <>
          {!isPasswordVisible ? (
            <Eye
              className="absolute cursor-pointer text-gray-400 top-[49%] right-0"
              onClick={() => setIsPasswordVisible(true)}
            />
          ) : (
            <EyeOff
              className="absolute cursor-pointer text-gray-400 top-[49%] right-0"
              onClick={() => setIsPasswordVisible(false)}
            />
          )}
        </>
      )}
      <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 h-full w-full select-none text-gray-400 text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-base peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
        {placeholder}
      </label>
    </div>
  );
};

export default Input;
