import { Loader2 } from "lucide-react";
import { FC, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";

import { forgetPassword } from "../../api/auth.api";
import Logo from "../../assets/media/svg/Wassapy-Logo.svg";
import Input from "../../shared/Form/Input";
import { setUser } from "../../store/actions";

export interface IForgetPasswordProps {
  onVerify: () => void;
}

const ForgetPassword: FC<IForgetPasswordProps> = ({ onVerify }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
  });

  const handleForgetPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);
    const { success, errors, data } = await forgetPassword(formData.email);
    setLoading(false);

    if (!success) return errors.forEach((error: string) => toast.error(error));

    dispatch(setUser(data));

    toast.success("OTP will be sent to your email!");
    onVerify();
  };

  return (
    <div className="max-w-2xl bg-white my-[88px] mx-auto lg:px-24 px-12 py-14 rounded-lg overflow-auto">
      <img src={Logo} alt="Wassapy" className="h-10" />
      <div className="mt-8">
        <div className="text-black lg:text-4xl text-2xl font-black">
          Forget Password
        </div>
        <div className="text-black lg:text-xl text-base font-normal mt-4">
          Please enter your email address.
        </div>
      </div>

      <form className="mt-6" onSubmit={handleForgetPassword}>
        <Input
          value={formData.email}
          className="mt-4"
          onTextChange={(value) =>
            setFormData({
              ...formData,
              email: value,
            })
          }
          type="email"
          placeholder="Email"
          disabled={loading}
        />
        <button
          type="submit"
          className="mt-10 mx-auto inline-flex items-center text-white px-8 py-[14.50px] text-lg font-bold w-full bg-primary active:bg-primary-dimmed hover:bg-primary-dimmed rounded-[50px] justify-center gap-2.5"
        >
          {loading ? <Loader2 className="animate-spin" /> : "Submit"}
        </button>
      </form>
    </div>
  );
};

export default ForgetPassword;
