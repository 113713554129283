import { X } from "lucide-react";
import { FC, ReactNode, useEffect } from "react";
import { Fade } from "react-reveal";

export interface IPopupProps {
  onClose: () => void;
  children: ReactNode;
}

const Popup: FC<IPopupProps> = ({ onClose, children }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", (e) => {
      if (e.key === "Escape") onClose();
    });
  }, []);

  return (
    <Fade duration={500}>
      <div className="fixed w-full h-full bg-black top-0 left-0 transition-all duration-300 overflow-auto z-10 bg-opacity-30">
        <X
          className="absolute top-5 right-5 text-white w-8 h-8 cursor-pointer"
          onClick={() => {
            onClose();
          }}
        />
        <div className="container">{children}</div>
      </div>
    </Fade>
  );
};

export default Popup;
