import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  setIsForgetPasswordModalOpened,
  setIsPopupOpened,
  setIsSignUpModalOpened,
} from "../store/actions";

const useDesktopApp = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const openPopups = () => {
    const action = searchParams.get("action");

    if (action === "forget-password") {
      dispatch(setIsPopupOpened(true));
      dispatch(setIsForgetPasswordModalOpened(true));
    }

    if (action === "sign-up") {
      dispatch(setIsPopupOpened(true));
      dispatch(setIsSignUpModalOpened(true));
    }
  };

  useEffect(() => {
    openPopups();
  }, []);

  return {};
};

export default useDesktopApp;
