import { useDispatch } from "react-redux";

import JoinUsImage from "../../../assets/media/png/join-us.png";
import {
  setIsPopupOpened,
  setIsSignUpModalOpened,
} from "../../../store/actions";

const JoinUs = () => {
  const dispatch = useDispatch();

  return (
    <div className="container py-7">
      <div className="bg-primary rounded-xl flex lg:flex-row flex-col justify-between items-center bg-pattern bg-cover bg-no-repeat">
        <div className="p-12">
          <div className="lg:text-6xl md:text-5xl text-[40px] text-white font-bold">
            Join us now 👊
          </div>
          <p className="text-white max-w-lg mt-6">
            {/* Generate motivation text */}
            You can join us now and get the best experience ever with our app.
          </p>
          <button
            onClick={() => {
              dispatch(setIsSignUpModalOpened(true));
              dispatch(setIsPopupOpened(true));
            }}
            className="bg-white text-primary px-8 py-3 rounded-3xl mt-6 font-semibold hover:text-white hover:bg-primary-dimmed transition-all duration-200"
          >
            Sign Up
          </button>
        </div>

        <div>
          <img src={JoinUsImage} alt="Wassapy" className="max-h-[300px]" />
        </div>
      </div>
    </div>
  );
};

export default JoinUs;
