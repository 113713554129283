import { useState } from "react";
import { useSelector } from "react-redux";
import { Fade } from "react-reveal";

import { IOption } from "../../models/app";
import { IState } from "../../models/store";
import Popup from "../../shared/Popup";
import { appleIcon, windowsIcon } from "../../utils/icons";

const DownloadAppButton = () => {
  const isMacOs = navigator.userAgent.indexOf("Mac OS X") !== -1;

  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const { options } = useSelector((state: IState) => state.appReducer);

  return (
    <>
      <Fade bottom delay={400}>
        <p className="text-center text-gray-500 md:text-2xl text-xl font-normal mt-8">
          We are a platform, which has services that can help you when it comes
          to sending <br className="lg:inline-block hidden" /> messages via
          WhatsApp to a group of people with one click
        </p>

        <div
          className="mt-14 mx-auto inline-flex items-center text-white px-8 py-[14.50px] text-xl font-medium bg-primary active:bg-primary-dimmed hover:bg-primary-dimmed rounded-[50px] justify-center gap-2.5 cursor-pointer"
          onClick={() => setIsDownloadModalOpen(true)}
        >
          {isMacOs ? appleIcon(26, 26) : windowsIcon(26, 26)}
          Download App For {isMacOs ? "MacOS" : "Windows"}
        </div>
      </Fade>

      {isDownloadModalOpen && (
        <Popup onClose={() => setIsDownloadModalOpen(false)}>
          <div className="max-w-2xl bg-white my-[80px] mx-auto lg:px-24 px-8 py-14 rounded-lg overflow-auto flex flex-col items-center">
            <div className="text-black lg:text-3xl text-2xl font-black">
              Before Downloading!
            </div>
            <div className="text-black lg:text-lg text-base font-normal mt-4 text-center">
              Please read these instructions carefully before downloading the
              app to avoid any problems or blocking your account
            </div>

            <div className="mt-10">
              <div className="text-gray-500">
                ✅ &nbsp; This app works with{" "}
                <span className="underline text-primary">
                  WhatsApp Business
                </span>{" "}
                only
                <div className="text-gray-500 mt-3">
                  ✅ &nbsp; Your account should be active and you engage with
                  your customers in a continuous way
                </div>
                <div className="text-gray-500 mt-3">
                  ✅ &nbsp; You can send up to 1000 messages per day 200 in one
                  time, but please start first with 200 messages per day and
                  then increase the number gradually
                </div>
              </div>
            </div>
            <a
              href={
                isMacOs
                  ? options.find(
                      (option: IOption) => option.name === "mac_package"
                    )?.option_value
                  : options.find(
                      (option: IOption) => option.name === "win_package"
                    )?.option_value
              }
              target="_blank"
              rel="noreferrer"
              className="mt-14 mx-auto inline-flex items-center text-white px-8 py-[14.50px] lg:text-xl text-base font-medium bg-primary active:bg-primary-dimmed hover:bg-primary-dimmed rounded-[50px] justify-center gap-2.5"
            >
              {isMacOs ? appleIcon(26, 26) : windowsIcon(26, 26)}
              Download App For {isMacOs ? "MacOS" : "Windows"}
            </a>
          </div>
        </Popup>
      )}
    </>
  );
};

export default DownloadAppButton;
