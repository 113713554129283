import Transactions from "./components/Transactions";
import UserInfo from "./components/UserInfo";

const Profile = () => {
  return (
    <div className="container py-14">
      <UserInfo />
      <Transactions />
    </div>
  );
};

export default Profile;
