import { IFeature } from "@/models/features";

import BulkSendImage from "../assets/media/png/bulk-send.png";
import ContactsImage from "../assets/media/png/contacts.png";
import GroupsImage from "../assets/media/png/groups.png";
import BulkSendIcon from "../assets/media/svg/bulk-send.svg";
import ContactsIcon from "../assets/media/svg/contacts.svg";
import GroupsIcon from "../assets/media/svg/groups.svg";

export const features: IFeature[] = [
  {
    title: "Bulk Send",
    description: "Send messages to multiple contacts at once.",
    image: BulkSendImage,
    icon: BulkSendIcon,
    iconBG: true,
    list: [
      "Send messages up to 1000 contacts at once.",
      "Include images, videos, documents, and more.",
      "Avoid spamming your recipients by following WhatsApp's terms of service.",
    ],
  },
  {
    title: "Contacts",
    description: "Access & Export your WhatsApp contacts.",
    image: ContactsImage,
    icon: ContactsIcon,
    iconBG: true,
    list: [
      "Access your contacts from the platform.",
      "Export your contacts to an Excel file for easy management.",
      "Keep your contacts up-to-date by syncing them with the platform regularly.",
    ],
  },
  {
    title: "Groups",
    description: "Manage your WhatsApp groups with ease.",
    image: GroupsImage,
    icon: GroupsIcon,
    iconBG: true,
    list: [
      "View the list of groups that you are a member of.",
      "See the participants of each group, including their phone numbers.",
      "Export the participants of a group to an Excel file for easy management.",
      "Keep track of the members of your groups by exporting them regularly.",
    ],
  },
];
