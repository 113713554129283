import api from ".";

export const options = async () => {
  try {
    const response = await api().get("/api/options");
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
};
