import { IAction, IModalState } from "../../models/store";
import {
  SET_IS_FORGET_PASSWORD_MODAL_OPENED,
  SET_IS_LOGIN_MODAL_OPENED,
  SET_IS_RESET_PASSWORD_MODAL_OPENED,
  SET_IS_SIGN_UP_MODAL_OPENED,
  SET_IS_SUCCESS_MODAL_OPENED,
  SET_IS_VERIFY_ACCOUNT_MODAL_OPENED,
} from "../../store/types";

const initialState = {
  isPopupOpened: false,
  isLoginModalOpened: false,
  isSignUpModalOpened: false,
  isForgetPasswordModalOpened: false,
  isVerifyAccountModalOpened: false,
  isResetPasswordModalOpened: false,
  isSuccessModalOpened: false,
};

const modalReducer = (state: IModalState = initialState, action: IAction) => {
  switch (action.type) {
    case SET_IS_LOGIN_MODAL_OPENED:
      return {
        ...initialState,
        isLoginModalOpened: action.payload,
      };
    case SET_IS_SIGN_UP_MODAL_OPENED:
      return {
        ...initialState,
        isSignUpModalOpened: action.payload,
      };
    case SET_IS_FORGET_PASSWORD_MODAL_OPENED:
      return {
        ...initialState,
        isForgetPasswordModalOpened: action.payload,
      };
    case SET_IS_VERIFY_ACCOUNT_MODAL_OPENED:
      return {
        ...initialState,
        isVerifyAccountModalOpened: action.payload,
      };
    case SET_IS_RESET_PASSWORD_MODAL_OPENED:
      return {
        ...initialState,
        isResetPasswordModalOpened: action.payload,
      };
    case SET_IS_SUCCESS_MODAL_OPENED:
      return {
        ...initialState,
        isSuccessModalOpened: action.payload,
      };
    default:
      return state;
  }
};

export default modalReducer;
