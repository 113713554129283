import { Fade } from "react-reveal";

import TextPage from "../../components/TextPage";

const RefundPolicy = () => {
  return (
    <div className="container py-14 min-h-[calc(100vh-186px)]">
      <TextPage>
        <Fade top>
          <h1>
            <strong>Refund Policy</strong>
          </h1>

          <p>
            Thanks for subscribing to our services at &nbsp;
            <a href="https://wassapy.com/">wassapy.com</a> operated by Wassapy Co.
          </p>

          <p>
            We offer a full money-back guarantee for all purchases made on our
            website. If you are not satisfied with the product that you have
            purchased from us, you can get your money back no questions asked.
            You are eligible for a full reimbursement within 7 calendar days of
            your purchase.
          </p>

          <p>
            To start a refund, you can contact us at &nbsp;
            <a href="mailto:info@wassapy.com">info@wassapy.com</a>
          </p>

          <p>
            After the 7-day period you will no longer be eligible and
            won&apos;t be able to receive a refund. We encourage our customers
            to try the service in the first two weeks after their purchase to
            ensure it fits your needs.
          </p>

          <p>
            If you have any additional questions or would like to request a
            refund, feel free to contact us. &nbsp;
            <a href="mailto:info@wassapy.com">info@wassapy.com</a>
          </p>
        </Fade>
      </TextPage>
    </div>
  );
};

export default RefundPolicy;
