import Pricing from "../../components/Pricing";

const Prices = () => {
  return (
    <div>
      <Pricing />
    </div>
  );
};

export default Prices;
