import Cookies from "js-cookie";
import { User, X } from "lucide-react";
import { Dispatch, FC, SetStateAction } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import { IState } from "@/models/store";

import {
  setIsAuth,
  setIsLoginModalOpened,
  setIsPopupOpened,
  setIsSignUpModalOpened,
  setUser,
} from "../../../store/actions";
import styles from "../TopBar.module.css";

export interface ISideBarProps {
  isMenuOpened: boolean;
  setIsMenuOpened: Dispatch<SetStateAction<boolean>>;
}

const SideBar: FC<ISideBarProps> = ({ isMenuOpened, setIsMenuOpened }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appData = useSelector((state: IState) => state.appReducer);

  const handleLogout = () => {
    Cookies.remove(process.env.REACT_APP_AUTH_COOKIE_NAME || "token");
    dispatch(setUser(null));
    dispatch(setIsAuth(false));
    navigate("/");
  };

  return (
    <div
      className={`fixed w-full h-full bg-black top-0 left-0 transition-all duration-300 lg:hidden ${
        isMenuOpened ? "z-10 bg-opacity-30" : "-z-10 bg-opacity-0"
      }`}
    >
      <div
        className={`bg-stone-50 fixed w-64 top-0 h-full shadow-lg transition-all duration-300 pt-12 ${
          isMenuOpened ? "left-0" : "-left-64"
        }`}
      >
        <X
          className="absolute top-5 right-5 text-gray-500 cursor-pointer"
          onClick={() => setIsMenuOpened(false)}
        />

        <div className="flex flex-col py-4 px-10 gap-3">
          <NavLink
            to="/"
            className={styles.topbarLink}
            onClick={() => setIsMenuOpened(false)}
          >
            Home
          </NavLink>
          <HashLink
            smooth
            to="/#features"
            className={styles.topbarLink}
            onClick={() => setIsMenuOpened(false)}
          >
            Features
          </HashLink>
          <NavLink
            to="/prices"
            className={styles.topbarLink}
            onClick={() => setIsMenuOpened(false)}
          >
            Pricing
          </NavLink>
          <NavLink
            to="/terms"
            className={styles.topbarLink}
            onClick={() => setIsMenuOpened(false)}
          >
            Terms and Conditions
          </NavLink>
          <HashLink
            smooth
            to="/#contact"
            className={styles.topbarLink}
            onClick={() => setIsMenuOpened(false)}
          >
            Contact Us
          </HashLink>
        </div>

        {appData.isAuthorized && appData.user ? (
          <div
            className="flex flex-col gap-2 px-10 mt-3"
            onClick={() => setIsMenuOpened(false)}
          >
            <Link to="/profile" className="flex items-center gap-2">
              <div className="w-10 h-10 bg-primary bg-opacity-10 rounded-full flex items-center justify-center">
                <User className="text-primary" />
              </div>
              <div className="text-black text-md font-medium">
                {appData.user.name}
              </div>
            </Link>
            <span
              className="underline text-primary cursor-pointer mx-auto"
              onClick={handleLogout}
            >
              Logout
            </span>
          </div>
        ) : (
          <div className="flex gap-3 mt-4 flex-col px-10">
            <button
              className="text-primary h-10 px-8 bg-[#F8F8F8] rounded-[50px] border border-primary font-medium active:text-white active:bg-primary hover:text-white hover:bg-primary text-base"
              onClick={() => {
                setIsMenuOpened(false);
                dispatch(setIsPopupOpened(true));
                dispatch(setIsLoginModalOpened(true));
              }}
            >
              Log In
            </button>
            <button
              className="h-10 px-8 text-white rounded-[50px] border active:border-primary-dimmed hover:border-primary-dimmed active:bg-primary-dimmed hover:bg-primary-dimmed border-primary bg-primary font-medium text-base"
              onClick={() => {
                setIsMenuOpened(false);
                dispatch(setIsPopupOpened(true));
                dispatch(setIsSignUpModalOpened(true));
              }}
            >
              Sign Up
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SideBar;
