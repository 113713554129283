import { ILogin, IRegister } from "@/models/auth";

import api from ".";

export const login = async (credentials: ILogin) => {
  try {
    const response = await api().post(`/api/auth/login`, credentials);
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
};

export const register = async (userData: IRegister) => {
  try {
    const response = await api().post(`/api/auth/register`, userData);
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
};

export const getUserFromToken = async () => {
  try {
    const response = await api().get(`/api/auth/get-user`);
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
};

export const verifyUser = async (code: number, userId: number) => {
  try {
    const response = await api().post(`/api/auth/verify`, { code, userId });
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
};

export const resendOtp = async (email: string) => {
  try {
    const response = await api().post(`/api/auth/resend-otp`, { email });
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
};

export const forgetPassword = async (email: string) => {
  try {
    const response = await api().post(`/api/auth/forget-password`, { email });
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
};

export const resetPassword = async (password: string, userId: number) => {
  try {
    const response = await api().post(`/api/auth/reset-password`, {
      password,
      userId,
    });
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
};
