import { BadgeCheck } from "lucide-react";
import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";

import { setIsPopupOpened, setIsSuccessModalOpened } from "../../store/actions";

export interface ISuccessProps {
  message?: string;
}

const Success: FC<ISuccessProps> = ({
  message = "Process Done Successfully",
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => {
      dispatch(setIsPopupOpened(false));
      dispatch(setIsSuccessModalOpened(false));
    }, 2000);
  }, []);

  return (
    <div className="max-w-2xl bg-white my-[88px] mx-auto lg:px-24 px-12 py-14 rounded-lg overflow-auto">
      <div className="flex justify-center">
        <BadgeCheck size={100} className="text-primary" />
      </div>
      <div className="text-black lg:text-3xl text-xl font-black text-center mt-4">
        {message}
      </div>
    </div>
  );
};

export default Success;
